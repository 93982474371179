import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const index = () => (
  <div className=" max-w-screen-xl mx-auto">
    <div className="w-full min-h-min bg-lateralOrange ">
      <StaticImage
        src="../assets/bridge.jpg"
        alt="A bridge"
        className="w-full"
      />
    </div>
    <div className="w-full flex justify-between  md:grid-cols-w">
      <div>Lateral Capital® Opportunity Fund, LP</div>
      <div className=" bg-lateralOrange ">
        <h3>Fund Overview</h3>
        <h3>For Accredited Investors</h3>
        <p>Updated to June 30, 2024</p>
      </div>
    </div>

    <p>
      Headquartered in Sarasota, Florida, Lateral Capital is a Micro Venture
      Capital Fund for accredited investors. Over the last ten years, our
      Limited Partnerships have invested in 87 companies across 21 states. As of
      June 30, 2024, projected gross returns for these funds are about 3.2X
      invested capital (MOIC), ahead of the 2.2X to 2.6X “industry average” for
      investments in Early-Stage Companies.{' '}
    </p>
  </div>
)
export default index
